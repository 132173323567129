import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { darkTheme, lightTheme } from "./utils/Themes.js";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Wedding } from "./pages/Wedding/Wedding.jsx";
import { Home } from "./pages/home/Home.jsx";

function App() {
  const [darkMode] = useState(true);
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        <Routes>
          <Route path="/profile" element={<Home />} />
          <Route path="/" element={<Wedding />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
