import { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogTitle } from "@mui/material";
import "./wedding.css";
import audio from "./audio.mp3";
import weddingGif from "./wedding.gif";

export const Wedding = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0 });
  const audioRef = useRef(null);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const weddingDate = new Date("December 07, 2024 15:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = weddingDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));

      if (distance < 0) {
        clearInterval(interval);
        document.querySelector(".countdown").innerHTML = "To'y boshlandi!";
      } else {
        setTimeLeft({ days });
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, []);

  const handlePlayAudio = () => {
    setOpen(false);
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Audio playback error:", error);
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SimpleDialog = () => {
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          <Button onClick={handlePlayAudio}>
            <strong style={{ fontSize: "30px" }}>Play</strong>
          </Button>
        </DialogTitle>
      </Dialog>
    );
  };

  return (
    <div className="main-body">
      <div className="invitation">
        <img src={weddingGif} height={300} alt="" />
        <header>
          <h1 className="headerWed">Hurmatli mehmonlar!</h1>
          <p>Sizni nikoh to'yimning faxriy mehmoni bo'lishga taklif qilaman.</p>
          <p>
            Qalblar ezguliklarga to‘la bo‘lgan ushbu qutlug‘ kunda do‘stlar
            yonida bo‘ling, hurmat bilan <strong>Mirzajanovlar</strong> oilasi
          </p>{" "}
        </header>
        <section className="details">
          <p>
            <strong>To'y kuni:</strong> 7-8 Dekabr kunlar
          </p>
          <p>
            <strong>To'y manzili:</strong> Namangan viloyati
          </p>
          <a
            href="https://maps.app.goo.gl/Y2tdWiMKvSAM5sBKA"
            target="_blank"
            rel="noopener noreferrer"
            className="map-button"
          >
            Karta orqali ochish
          </a>
        </section>
        {/* <div className="countdown">
          <div id="days" className="time">
            {timeLeft.days} <span>kun</span>
          </div>
        </div> */}
        <audio style={{ marginTop: "10px" }} ref={audioRef} controls>
          <source src={audio} type="audio/mpeg" />
        </audio>
      </div>
      <SimpleDialog />
    </div>
  );
};
